<template>
    <div class="viewContainer reportsContainer">
        <h1>{{$t('reports.title')}}</h1>
        <div>
            <button id="addNewReport" @click="addReport()" class="iconButton add">{{$t('reports.addNewReport')}}</button>
        </div>
        <div class="spacer l" />
        <div class="winelistContainer noResults" v-if="reports.length === 0 && loading === true">
            <LoadingAnimation />
        </div>
        <div class="winelistContainer" v-if="reports.length > 0">
            <b-table 
                ref="reportList"
                :items="reports" 
                :fields="fields"
                sticky-header    
            >
                <template #cell(title)="data">
                    {{data.item.attributes.title}}
                </template>
                <template #cell(type)="data">
                    {{reportTypes[data.item.attributes.type]}}
                </template>
                <template #cell(dateTimeRange)="data">
                    {{ dateToFormattedstring(data.item.attributes.dateFrom, false)}} - {{  dateToFormattedstring(data.item.attributes.dateTo, false)}}
                </template>
                <template #cell(showReport)="data">
                    <button @click="showReport(data.item)" class="iconButton details">{{$t('reports.showReport')}}</button>
                </template>
                <template #cell(editReport)="data">
                    <button v-if="!isInventoryReport(data.item)" @click="editReport(data.item.id)" class="iconButton edit">{{$t('reports.editReport')}}</button>
                </template>
                <template #cell(deleteReport)="data">
                    <button v-if="!isInventoryReport(data.item)" @click="deleteReport(data.item.id)" class="iconButton delete">{{$t('reports.deleteReport')}}</button>
                </template>
            </b-table>
        </div>
        <div class="noResults"  v-if="reports.length <= 0">
            <div>
            <img src="../assets/img/no-reports.svg">
            <span class="emptyState">{{ $t('reports.noReports') }}</span>
        </div>
        </div>
        <NewReportModal ref="newReportModal" />

        <InventoryReportModal ref="inventoryReportModal" />
        <ReportDetailModal ref="reportDetailModal" />
    </div>
</template>
<script>
import DataService from '@/services/StrapiService'
import NewReportModal from '@/components/modals/NewReportModal.vue'
import ReportDetailModal from '@/components/modals/ReportDetailModal.vue'
import { mapGetters } from 'vuex'
import LoadingAnimation from '@/components/LoadingAnimation.vue'
import DateTimeService from '../services/DateTimeService'
import InventoryReportModal from '../components/modals/InventoryReportModal.vue'

export default {
    name: 'Reports',
    components: { LoadingAnimation, NewReportModal, ReportDetailModal, InventoryReportModal },
    created () {
        this.loadReports();
    },
    data() {
        return {
            reports: [],
            loading: false,
            reportTypes: {
                'sold': this.$t('reports.soldWines'),
                'moved': this.$t('reports.movedWines'),
                'added': this.$t('reports.addedWines'),
                'deleted': this.$t('reports.deletedWines'),
                'inventory-dueDate': this.$t('inventory.dueDate'),
                'inventory-spotCheck': this.$t('inventory.spotCheck'),
            },
            reportData: {}
        }
    },
    methods: {
        async loadReports() {
            this.loading = true
            await DataService.findReports().then((response) => {
                this.reports = response['data'].data
            }).catch((err) => {
                console.log(err)
                this.loading = false
            })
            this.loading = false
        },
        addReport() {
            this.$refs.newReportModal.showModal()
        },
        dateToFormattedstring(date, time) {
          return DateTimeService.dateToFormattedstring(new Date(date), time, this.$i18n.locale)
        },
        async showReport(report) {        
          if (this.isInventoryReport(report)) {
            this.$refs.inventoryReportModal.showModal(report)
            return
          } else {
            this.$refs.reportDetailModal.showModal(report)
          }
        },
        editReport(id) {
            this.$refs.newReportModal.showModal(id)
        },
        isInventoryReport(report) {
            return report.attributes.type.includes('inventory')
        },
        async deleteReport(id) {
            //check if report belongs to this venue first
            let report = this.reports.find(report => report.id == id)
            if (report) {
                this.loading = true

                await DataService.deleteReport(report.id).then(() => {
                    this.loadReports()
                }).catch((err) => {
                    console.log(err)
                    this.loading = false
                })
                this.loading = false
            }
        },
    },
    computed: {
        fields() { 
              return [
                    {
                        key: 'title',
                        label: this.$t('reports.columnName')
                    },
                    {
                        key: 'type',
                        label: this.$t('reports.columnType')
                    },
                    {
                        key: 'dateTimeRange',
                        label: this.$t('reports.columnPeriod')
                    },
                    {
                        key: 'showReport',
                        label: ''
                    },
                    {
                        key: 'editReport',
                        label: ''
                    },
                    {
                        key: 'deleteReport',
                        label: ''
                    }
                ]
        },
        ...mapGetters(['getVenue', 'getReloadReports'])
    }, 
    watch: {
        getReloadReports() {
            this.loadReports();
        },
    }
}
</script>